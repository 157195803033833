import { JwtHelperService } from '@auth0/angular-jwt';

export interface IAuthData {
  id: string;
  permission: string;
  userType: string;
  LoginCount: number,
  LastLogin: Date,
  Approval_Status?:string,
  name?:string,
  email?:string
  timeZone?:string
  Client_Time_Zone?:string
}

export interface IDecodeToken {
  authData: IAuthData;
  exp: number;
  iat: number;
}

export enum SESSION_STORAGE {
  TOKEN = 'token',
  AUTH_DATA = 'auth_data',
  PROFILE_DATA = 'data',
  TOKEN_DECODE = 'token_decode',
  PROFILE_IMAGE_URL = 'profile_img_url',
  REGISTER_USER_ID = 'registredUserID',
  NEW_USER_DATA = "new_user_data"
}

export class SessionStorage {

  constructor() {
  }

  static get Token() {
    return sessionStorage.getItem(SESSION_STORAGE.TOKEN)
  }

  static set Token(value: string) {
    sessionStorage.setItem(SESSION_STORAGE.TOKEN, value)
  }

  static get Auth_Data(): IAuthData {
    return JSON.parse(sessionStorage.getItem(SESSION_STORAGE.AUTH_DATA))
  }

  static get Profile_Data(): IAuthData {
    return JSON.parse(sessionStorage.getItem(SESSION_STORAGE.PROFILE_DATA))
  }
  static set Profile_Data(value: IAuthData) {
    sessionStorage.setItem(SESSION_STORAGE.PROFILE_DATA, JSON.stringify(value))
  }

  static set Auth_Data(value: IAuthData) {
    sessionStorage.setItem(SESSION_STORAGE.AUTH_DATA, JSON.stringify(value))
  }
  static set NewUser_Data(value: IAuthData) {
    sessionStorage.setItem(SESSION_STORAGE.NEW_USER_DATA, JSON.stringify(value))
  }

  static get Profile_Image_URL(): string {
    return JSON.parse(sessionStorage.getItem(SESSION_STORAGE.PROFILE_IMAGE_URL));
  }

  static set Profile_Image_URL(value: string) {
    sessionStorage.setItem(SESSION_STORAGE.PROFILE_IMAGE_URL, JSON.stringify(value));
  }
  static set registredUserID(value: string) {
    sessionStorage.setItem(SESSION_STORAGE.REGISTER_USER_ID, JSON.stringify(value));
  }

  static get Is_Token_Expired() {
    try {
      return new JwtHelperService().isTokenExpired(this.Token);
    } catch (error) {
      console.log(error);
      return true;
    }
  }

  static get Token_Expiry_Date(): Date {
    try {
      return new JwtHelperService().getTokenExpirationDate(this.Token);
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  static remove(item_name: string) {
    sessionStorage.removeItem(item_name);
  }

  static removeAll() {
    sessionStorage.clear();
  }
  static set updateTimeZone(timezone:string){
    const data = this.Auth_Data;
    data.Client_Time_Zone = timezone;
    this.Auth_Data = data;
  }

}

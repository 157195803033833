// These urls for RouterModule
export const ngRoutes = {

    account: {
        url: 'account',
        childrens: {
            login: 'login',
            logout:'logout',
            signup: 'signup',
            forgot_password: 'forgot-password',
            recover_password: 'recover-password/:id',
            change_password: 'change-password',
            forgotPassword_message:'forgot-password-message',
            consultant_signup_info:'consultant-signup/personal-info',
            consultant_profession_info:'consultant-signup/profession-info',
            client_signup_info:'client-signup/personal-info',
            client_detail_info:'client-signup/personal-detail/:token/:id',
            client_submit_project:'client-signup/personal-detail/submit-project',
            client_call_schedule:'client-signup/personal-detail/call-schedule',
            client_submit_project_detail:'client-signup/personal-detail/submit-project-details',
            client_submit_project_detail_welcome:'client-signup/personal-detail/welcome',
            client_submit_project_detail_scheduled:'client-signup/personal-detail/meeting-scheduled',
        }
    },

    admin: {
        url: 'admin',
        childrens: {
            dummy: 'dummy',
            dashboard: 'dashboard',
            clients: {
                url: 'clients',
                childrens: {
                    active_clients: {
                        url: 'active-clients',
                        childrens: {
                            details: 'details',
                            jobs: 'jobs'
                        }
                    },
                    pending_clients: {
                        url: 'pending-clients',
                        childrens: {
                            details: 'details',
                            jobs: 'jobs'
                        }
                    },
                }
            },
            consultants: {
                url: 'consultants',
                childrens: {
                    all_consultants: 'all-consultants',
                    new_consultants: 'new-consultants'
                }
            },
            jobs: {
                url: 'jobs',
                childrens: {
                    active_jobs: 'active-jobs',
                    pending_jobs: 'pending-jobs'
                }
            },
            users: 'users'
        }
    },
    consultant: {
        url: 'consultant',
        childrens: {
            dashboard: 'dashboard',
            profile:'profile',
            pdfViewr:'pdfViewer',
            jobs:'projects-for-you',
            submission:'submission-status',

            personalityTest: {
                url: 'personalityTest',
                childrens: {
                    test_Details: {
                        url: 'detail',
                        // childrens: {
                        //     details: 'details',
                        //     jobs: 'jobs'
                        // }
                    },
                    model: {
                        url: 'model',

                    },
                    ptypes: {
                        url: 'types',

                    },
                    test: {
                        url: 'test',
                    },
                    result: {
                        url: 'result',
                    },
                    // pending_clients: {
                    //     url: 'pending-clients',
                    //     childrens: {
                    //         details: 'details',
                    //         jobs: 'jobs'
                    //     }
                    // },
                }
            },

            personality_Test:'iAgility-personality-Test',
            personal_Assessment:'personal-Assessment',
        }
    },
    client: {
        url: 'client',
        childrens: {
            clientDashboard: 'clientDashboard/:id/:id',
            dashboard: 'dashboard',
            jobs: 'jobs',
            profile_Matches: 'profile-matches',
            change_password:'change-password',
            featured_Consultant: 'featured-consultant',
            contract_Agreement: 'contract-agreement',
            askiAgility: 'ask-iAgility',
            charity: 'charity' ,
            charityhiring:'charityhiring',
            personal_Assessment: 'personal-Assessment',
            notifications:'notifications',
            personalityTest: {
                url: 'personalityTest',
                childrens: {
                    test_Details: {
                        url: 'detail',
                        // childrens: {
                        //     details: 'details',
                        //     jobs: 'jobs'
                        // }
                    },
                    model: {
                        url: 'model',

                    },
                    ptypes: {
                        url: 'types',

                    },
                    test: {
                        url: 'test',
                    },
                    result: {
                        url: 'result',
                    },
                    // pending_clients: {
                    //     url: 'pending-clients',
                    //     childrens: {
                    //         details: 'details',
                    //         jobs: 'jobs'
                    //     }
                    // },
                }
            }
        }
    }

}

// These urls for anchors or routerLink
export const AnchorURLs = {
    account: {
        login: `/${ngRoutes.account.url}/${ngRoutes.account.childrens.login}`,
        logout: `/${ngRoutes.account.url}/${ngRoutes.account.childrens.logout}`,
        signup: `/${ngRoutes.account.url}/${ngRoutes.account.childrens.signup}`,
        forgot_password: `/${ngRoutes.account.url}/${ngRoutes.account.childrens.forgot_password}`,
        recover_password: `/${ngRoutes.account.url}/${ngRoutes.account.childrens.recover_password}`,
        change_password: `/${ngRoutes.account.url}/${ngRoutes.account.childrens.change_password}`,
        forgotPassword_message: `/${ngRoutes.account.url}/${ngRoutes.account.childrens.forgotPassword_message}`,
        consultant_signup_info: `/${ngRoutes.account.url}/${ngRoutes.account.childrens.consultant_signup_info}`,
        consultant_profession_info: `/${ngRoutes.account.url}/${ngRoutes.account.childrens.consultant_profession_info}`,
        client_detail_info: `/${ngRoutes.account.url}/${ngRoutes.account.childrens.client_detail_info}`,
        client_submit_project: `/${ngRoutes.account.url}/${ngRoutes.account.childrens.client_submit_project}`,
        client_call_schedule: `/${ngRoutes.account.url}/${ngRoutes.account.childrens.client_call_schedule}`,
        client_submit_project_detail: `/${ngRoutes.account.url}/${ngRoutes.account.childrens.client_submit_project_detail}`,
        client_submit_project_detail_welcome: `/${ngRoutes.account.url}/${ngRoutes.account.childrens.client_submit_project_detail_welcome}`,
        client_submit_project_detail_scheduled: `/${ngRoutes.account.url}/${ngRoutes.account.childrens.client_submit_project_detail_scheduled}`,
    },

    admin: {
        admin: `/${ngRoutes.admin.url}`,
        dashboard: `/${ngRoutes.admin.url}/${ngRoutes.admin.childrens.dashboard}`,
        dummy: `/${ngRoutes.admin.url}/${ngRoutes.admin.childrens.dummy}`,

        clients: `/${ngRoutes.admin.url}/${ngRoutes.admin.childrens.clients.url}`,
        active_clients: `/${ngRoutes.admin.url}/${ngRoutes.admin.childrens.clients.url}/${ngRoutes.admin.childrens.clients.childrens.active_clients.url}`,
        pending_clients: `/${ngRoutes.admin.url}/${ngRoutes.admin.childrens.clients.url}/${ngRoutes.admin.childrens.clients.childrens.pending_clients.url}`,

        consultants: `/${ngRoutes.admin.url}/${ngRoutes.admin.childrens.consultants.url}`,
        all_consultants: `/${ngRoutes.admin.url}/${ngRoutes.admin.childrens.consultants.url}/${ngRoutes.admin.childrens.consultants.childrens.all_consultants}`,
        new_consultants: `/${ngRoutes.admin.url}/${ngRoutes.admin.childrens.consultants.url}/${ngRoutes.admin.childrens.consultants.childrens.new_consultants}`,

        jobs: `/${ngRoutes.admin.url}/${ngRoutes.admin.childrens.jobs.url}`,
        active_jobs: `/${ngRoutes.admin.url}/${ngRoutes.admin.childrens.jobs.url}/${ngRoutes.admin.childrens.jobs.childrens.active_jobs}`,
        pending_jobs: `/${ngRoutes.admin.url}/${ngRoutes.admin.childrens.jobs.url}/${ngRoutes.admin.childrens.jobs.childrens.pending_jobs}`,

        users: `/${ngRoutes.admin.url}/${ngRoutes.admin.childrens.users}`,
    },
    consultant: {
        consultant: `/${ngRoutes.consultant.url}`,
        consultant_image: `/${ngRoutes.consultant.url}/uploads/`,
        dashboard: `/${ngRoutes.consultant.url}/${ngRoutes.consultant.childrens.dashboard}`,
        profile: `/${ngRoutes.consultant.url}/${ngRoutes.consultant.childrens.profile}`,
        pdfViewer: `/${ngRoutes.consultant.url}/${ngRoutes.consultant.childrens.pdfViewr}`,
        jobs: `/${ngRoutes.consultant.url}/${ngRoutes.consultant.childrens.jobs}`,
        submission: `/${ngRoutes.consultant.url}/${ngRoutes.consultant.childrens.submission}`,
        personalityTest: `/${ngRoutes.admin.url}/${ngRoutes.consultant.childrens.personalityTest.url}`,


        personality_Test: `/${ngRoutes.consultant.url}/${ngRoutes.consultant.childrens.personality_Test}`,
        personal_Assessment: `/${ngRoutes.consultant.url}/${ngRoutes.consultant.childrens.personal_Assessment}`,


    },
    client: {
        consultant: `/${ngRoutes.client.url}`,
        dashboard: `/${ngRoutes.client.url}/${ngRoutes.client.childrens.dashboard}`,
        jobs: `/${ngRoutes.client.url}/${ngRoutes.client.childrens.jobs}`,
        profile_Matches: `/${ngRoutes.client.url}/${ngRoutes.client.childrens.profile_Matches}`,
        featured_Consultant: `/${ngRoutes.client.url}/${ngRoutes.client.childrens.featured_Consultant}`,
        contract_Agreement: `/${ngRoutes.client.url}/${ngRoutes.client.childrens.contract_Agreement}`,
        askiAgility: `/${ngRoutes.client.url}/${ngRoutes.client.childrens.askiAgility}`,
        charity: `/${ngRoutes.client.url}/${ngRoutes.client.childrens.charity}`,
        personal_Assessment: `/${ngRoutes.client.url}/${ngRoutes.client.childrens.personal_Assessment}`,
        personalityTest: `/${ngRoutes.client.url}/${ngRoutes.client.childrens.personalityTest.url}`,
        change_password: `/${ngRoutes.client.url}/${ngRoutes.client.childrens.change_password}`,
        notifications: `/${ngRoutes.client.url}/${ngRoutes.client.childrens.notifications}`,
    }
}

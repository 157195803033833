import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastrModelService {

  constructor(public toastr:ToastrService) { }

  public error(message){
    this.toastr.error('', message, {
      positionClass: 'toast-bottom-right',
      closeButton:true,
      timeOut:2000
    })
  }
  public custom(message) {
    this.toastr.error('Click &nbsp; <img src=\"assets/icons/Group 966.png\" > &nbsp; to save the skills', message, {
      positionClass: 'toast-bottom-right',
      closeButton: true,
      enableHtml:true,
      timeOut: 2000
    })
  }
  public customtoasterAddToProceed (message) {
    this.toastr.error('Click &nbsp; <img src=\"assets/icons/Group 966.png\" > &nbsp; to proceed', message, {
      positionClass: 'toast-bottom-right',
      closeButton: true,
      enableHtml:true,
      timeOut: 2000
    })
  }

  public success(message){
    this.toastr.success('', message, {
      positionClass: 'toast-bottom-left',
      closeButton:true,
      timeOut:2000
    })
  }
}

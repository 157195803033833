import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { USER_TYPE } from 'src/enums/users.enum';
import { environment } from 'src/environments/environment';
import { AnchorURLs } from 'src/routes/ng.routes';
import { LocalStorage } from 'src/storage/local.storage';
import { SessionStorage } from 'src/storage/session.storage';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(private router: Router,  private activatedRoute: ActivatedRoute,) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (LocalStorage?.Auth_Data?.userType == USER_TYPE.Admin)
      return true;
    if (LocalStorage?.Auth_Data?.userType == USER_TYPE.Client_Relation_Executive)
      return true;
    if (LocalStorage?.Auth_Data?.userType == USER_TYPE.Recruitment_Executive)
      return true;
    if (LocalStorage?.Auth_Data?.userType == USER_TYPE.Consultant)
      return true;
    if (LocalStorage?.Auth_Data?.userType == USER_TYPE.Client)
      return true;
    if (SessionStorage?.Auth_Data?.userType == USER_TYPE.Client)
      return true;
    if(window.location.href === environment.client_url + "#/client/contract-agreement?agreement=true"){
      window.location.href = environment.account_url + '#/logout' + '?agreement=true'
      return false
    }
    if (window.location.href.includes('documentManagment')) {    
      var queryString = window.location.href ? window.location.href.split('?')[1] :window.location.href.slice(1);
      window.location.href =environment.account_url + '#/logout?'  + queryString;
        // http://localhost:4400/#/client/dashboard?param1=63fc30f3103d65001329ee3a&param2=63fc30955f9c2000140a5e46&documentManagment=true
      return false;
    } 
    if(window.location.href.includes('interviewsuggestedConsultant')){
      var queryString = window.location.href ? window.location.href.split('?')[1] :window.location.href.slice(1);
      window.location.href =environment.account_url + '#/logout?'  + queryString
      return false
    }
    if(window.location.href.includes('SeeAcknowledgment')){
      var queryString = window.location.href ? window.location.href.split('?')[1] :window.location.href.slice(1);
      window.location.href =environment.account_url + '#/logout?'  + queryString
      return false
    }
    else{
      window.location.href = environment.account_url + '#/logout'
      return false
    }

   
   
    window.location.href = environment.account_url + '#/logout'
    return false

  }

}

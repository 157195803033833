export enum USER_TYPE {
    Admin = 'admin',
    SubAdmin = 'sub_admin',
    Consultant = 'consaltant',
    Client = 'client',
    Client_Relation_Executive = 'Client Relation Executive',
    Recruitment_Executive = 'Recruitment Executive',

}
export enum AMERICAN_TIMEZONE {
    'Eastern Standard Time (EST)' = 'America/New_york',
    'Central Standard Time (CST)' = 'America/Chicago',
    'Pacific Standard Time (PST)' = 'America/Los_Angeles',
    'Mountain Standard Time (MST)' = 'America/Denver'
}
export enum TIMEZONE_SYMBOL {
    'Eastern Standard Time (EST)' = 'EST',
    'Central Standard Time (CST)' = 'CST',
    'Pacific Standard Time (PST)' = 'PST',
    'Mountain Standard Time (MST)' = 'MST'
}
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClientServiceService } from 'src/app/client/client-service.service';
 import { ToastrModelService } from 'src/app/toastr-model.service';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent implements OnInit {
  public messageText:any;
  public messageList:any = [];
  constructor(
     private dialogRef: MatDialogRef<MessageBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public toaster: ToastrModelService,
    public clientService: ClientServiceService

) { }

  ngOnInit(): void {
    console.log(this.data?.Admin_Message_Client)
    this.messageList = this.data?.Admin_Message_Client
  }
 
  closeDialog() {
    this.dialogRef.close({ data: false })
}

}


import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

 

@Injectable()
export class ApiService {
    signupDetailNavigation: Subject<any>;
     
    constructor(private http: HttpClient,
        private router: Router) {
    }
    live() {
        return this.http.get(environment.server_url + '/live').pipe(
            map<any, any>(res => {
                if (res) {
                    return res;
                }
                return res
            })
        )
    }
   
}

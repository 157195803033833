<div class="add-comment-container"  >
    <div class="account-container">
        <div class="row justify-content-center w-100">
            <div class="col-12 col-sm-12 col-md-12 p-0">
                <div id="content">
                    <div class="cross row">
                        <div class="cross-icon">
                          <img (click)="closeDialog()"  src="assets/icons/Subtraction-grey.svg" alt="">
                      </div>
                      </div>
                    <div class="row">
                        <div class="col heading title pl-3">
                            <p class="mb-0" >
                                Message(s) from iAgility
                            </p>
                        </div>
                    </div>
                  <!-- <div class="container">
                    <div class="row">
                        <textarea class="scroll-5" name="message" placeholder="Type here.." id=""    ></textarea>
                    </div>
                  </div> -->
                     
                  <div class="container" style="margin-top:20px; padding-left: 0;" *ngIf="messageList.length > 0">
                    <div class="row  scroll-5" style="height:200px;    padding-left: 2px;" >
                         
                        <div class="col-md-12" *ngFor="let item of messageList;let i=index">
                            <p class="message">{{item?.message}}</p>
                            <p class="message date"> 
                                <!-- {{item?.date  | date: 'hh:mm a' :'UTC -4'}} EST |  {{item?.date  | date: 'MM/dd/yyyy' :'UTC -4'}} </p> -->
                                {{item?.date | timezone}}
                            <div class="border-bottom"  ></div>
                        </div>
                    </div>
                  </div>
                     

                </div>

            </div>
        </div>
    </div>

 
   
</div>

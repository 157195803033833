import { environment } from 'src/environments/environment';

export const API_ROUTE = {
    Account: {
        Login: `${environment.server_url}/user/login`,
        Signup_consultant_info: `${environment.server_url}/user/signupConsultant`,
        take_to_my_dashboard_check: `${environment.server_url}/api/takeToDashboardCheck`,
        activate_consultatnt_account: `${environment.server_url}/user/activationConsultant`,
        Signup: '',
        regenerate_activation_code: `${environment.server_url}/user/codeRegenerate`,
        change_password: `${environment.server_url}/user/changePasswordFromToken`,
        passwordChnage: `${environment.server_url}/user/passwordChange`,
        forgot_password: `${environment.server_url}/user/forgetPassword`,
        recover_password: ``,
        Signup_client_info: `${environment.server_url}/user/saveClient`,
        client_CodeActivation: `${environment.server_url}/user/clientActivation`,
        checkEmail: `${environment.server_url}/user/findUserEmail`,
    },
    Admin: {
        Users: {
            admins_list: `${environment.server_url}/api/getadminlist`,
            assignClients_list: `${environment.server_url}/api/getAssignedClientList`,
            add_admin: `${environment.server_url}/api/saveadmin`,
            update_admin: `${environment.server_url}/api/updateadmin`,
            delete_admin: `${environment.server_url}/api/deleteadmin`,
            get_admin: `${environment.server_url}/api/deleteadmin`,
        },
        Clients: {
            no_of_active_clients: `${environment.server_url}/api/getActiveClientCount`,
            no_of_pending_clients: `${environment.server_url}/api/getPendingClientCount`,
            get_client_details: `${environment.server_url}/api/getOneClient`,
            get_client_jobs: `${environment.server_url}/api/getCleintJobs`,
            get_active_clients: `${environment.server_url}/api/getActiveClientList`,
            get_pending_clients: `${environment.server_url}/api/getPendindCleintList`,
            get_client_list: `${environment.server_url}/api/getClientList`,
            save_client_comment: `${environment.server_url}/api/Comment`,
            ApprovedDisapproveCleint: `${environment.server_url}/api/ApprovedDisapproveCleint`,
            submit_application: `${environment.server_url}/api/SaveSubmission`,
            update_application: `${environment.server_url}/api/UpadateApplication`,

        },
        Jobs: {
            no_of_active_jobs: `${environment.server_url}/api/getActiveJobCount`,
            no_of_pending_jobs: `${environment.server_url}/api/getPendingJobCount`,
            get_active_jobs: `${environment.server_url}/api/getActiveJobList`,
            get_pending_jobs: `${environment.server_url}/api/getPendingJobList`,
            get_job_details: `${environment.server_url}/api/findOneJob`,
            ApprovedDisapproveJob: `${environment.server_url}/api/ApprovedDisapproveJOb`,
        },
        Consultants: {
            no_of_all_consultants: `${environment.server_url}/api/getAllConsaltantCount`,
            no_of_new_consultants: `${environment.server_url}/api/getNewConsaltantCount`,
            get_all_consultants_list: `${environment.server_url}/api/getAllConsultantList`,
            getNewConsaltantList: `${environment.server_url}/api/getNewConsultantList`,
            update_consultant_feedback: `${environment.server_url}/api/UpadateComment`,
            getGCID: `${environment.server_url}/api/getConsultantByID`,
            SendAgreementReminder: `${environment.server_url}/api/SendAgreementReminder`,
            shareJob: `${environment.server_url}/api/assignConsultantJobs`,
            ApprovedDisapproveConsultants: `${environment.server_url}/api/ApprovedDisapproveConsaltant`,
            featuredConsultant: `${environment.server_url}/api/feature-consultant`,
        },
        Dashboard: {
            submissions_list: `${environment.server_url}/api/SubmissionListCount`,
            hiring_list: `${environment.server_url}/api/getHiringList`,
            activities_list: `${environment.server_url}/api/getActivityFeedList`
        },
    },
    Consultant: {
        get_Info: `${environment.server_url}/api/getConsultantProfile`,
        Update_Consultant: `${environment.server_url}/api/updateConsultantPersonalInformation`,
        Update_Availbility_Status: `${environment.server_url}/api/updateAvailability`,
        Get_Buisness_Skills: `${environment.server_url}/api/BussniesSkilss`,
        Get_Technical_Skills: `${environment.server_url}/api/technicalskills`,
        Get_SUB_Technical_Skills: `${environment.server_url}/api/skilssbyName`,
        Get_COTS: `${environment.server_url}/api/cots`,
        Get_SUB_COTS: `${environment.server_url}/api/cotbyName`,
        Get_Jobs_List: `${environment.server_url}/api/JobList`,
        Get_Submission_Jobs_List: `${environment.server_url}/api/getConsultantApplication`,
        remove_App: `${environment.server_url}/api/RemoveConsultantjobs`,
        withDrawApp: `${environment.server_url}/api/RemoveSubmission`,
        apply_App: `${environment.server_url}/api/Applications`,
        Get_Jobs_Detail: `${environment.server_url}/api/findOneJob`,
        save_Favorite_Job: `${environment.server_url}/api/saveFavouriteJobs`,
        remove_Favorite_Job: `${environment.server_url}/api/removeFavouriteJobs`,
        Agreement_Upload: `${environment.server_url}/api/addagreement`,
        Picture_Verfiation_File_Upload: `${environment.server_url}/api/addpictureidprof`,
        W_Auth_File_Upload: `${environment.server_url}/api/addworkauthprof`,
        Get_Submission_Detail: `${environment.server_url}/api/findOneApplication`,
        save_Personality_Test: `${environment.server_url}/api/updateConsultantPT`,
        save_Assessment_Result: `${environment.server_url}/api/updateConsultantPA`,
        SuggestInterviewAccept: `${environment.server_url}/api/SuggestInterviewAccept`,
        interviewAccept: `${environment.server_url}/api/interviewAccept`,
        get_Buisness_Skill_Info: `${environment.server_url}/api/findOneBuniessSkill`,
        addPortfolios: `${environment.server_url}/api/add-portfolios`,
        Get_COUNTS: `${environment.server_url}/api/getConsultantApplicationsList`,
        updateApplication: `${environment.server_url}/api/updateApplication`,

    },
    Client: {
        get_Info: `${environment.server_url}/api/getOneClient`,
        change_Logo: `${environment.server_url}/api/ClientProfilePicUpdate`,
        statuses_count: `${environment.server_url}/api/getClientStatusesCount`,
        submission_Count: `${environment.server_url}/api/getSubmissionAwaitingApplications`,
        job_Count: `${environment.server_url}/api/getOpenJobCount`,
        open_job_Count: `${environment.server_url}/api/getOpenJobCountForClient`,
        Get_Projects_List: `${environment.server_url}/api/getCleintJobs`,
        Get_Project_Detail: `${environment.server_url}/api/findOneJob`,
        register_byFile: `${environment.server_url}/api/saveJobfile`,
        saveClient: `${environment.server_url}/api/SaveJobFromDashboard`,
        saveJob: `${environment.server_url}/api/SaveJob`,
        Get_FeaturedConsultant_List: `${environment.server_url}/api/getFeaturedConsaltantList`,
        askIAgility: `${environment.server_url}/api/InquirySave`,
        makeInquey: `${environment.server_url}/api/MakeInquiry`,
        getaskIAgility: `${environment.server_url}/api/getAdminbyuserid`,
        upload_Agreement: `${environment.server_url}/api/addagreementClient`,
        update_Project: `${environment.server_url}/api/UpadateJob`,
        Get_Profile_Matches_List: `${environment.server_url}/api/getSubmissionSKillbyjob`,
        getGCID: `${environment.server_url}/api/getConsultantByUserId`,
        generateInterview: `${environment.server_url}/api/genrateInterview`,
        updateApplicationStatus: `${environment.server_url}/api/updateApplication`,
        getOccopiedDateOfMonth: `${environment.server_url}/api/getOccupiedDateForMonths`,
        getOccupiedDateSlots: `${environment.server_url}/api/getOccupiedDateSlots`,
        getSlotsByZones: `${environment.server_url}/api/getOccupiedSlotsTimezone`,
        saveMeeting: `${environment.server_url}/api/saveMeeting`,
        generateExtentendAnoffer: `${environment.server_url}/api/generateExtentendAnoffer`,
        generateExtentendAnofferNonOBP: `${environment.server_url}/api/generateExtendAnoffer`,
        get_active_jobs: `${environment.server_url}/api/getConsultantjobsList`,
        agreementSave: `${environment.server_url}/api/sendAgreementForm`,
        projectCallDashboard: `${environment.server_url}/api/projectCallDashboardaftersignup`,
        notifications: `${environment.server_url}/api/notifications`,
        clientTimeZone: `${environment.server_url}/api/UpdateClientTimeZone`, 
        getclientTimeZone: `${environment.server_url}/api/findClientTimeZone`, 
        downloadFileDM: `${environment.server_url}/api/updateDocuments`, 
        findOneApplication: `${environment.server_url}/api/findOneApplication`,
        SuggestInterviewClient: `${environment.server_url}/api/SuggestInterviewClient`,
        interviewAccept: `${environment.server_url}/api/interviewAcceptClient`,
        saveHiring: `${environment.server_url}/api/ConsultantApplicationHired`,
        saveCharityProcess: `${environment.server_url}/api/saveCharity`,
        declinedApplicationHiringProcess: `${environment.server_url}/api/clientDeclinedAcknowledgement`,

    }
};

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from 'src/guards/admin.guard';
import { AuthComponent } from './auth/auth.component';
import { HealthcheckComponent } from './healthcheck/healthcheck.component';
 
 

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'client' },
  { path: 'live', component: HealthcheckComponent },
  { path: 'auth/:id', component: AuthComponent },
  {
    path: 'client',
    canActivate: [AdminGuard],
    loadChildren: () => import('./client/client.module').then(mod => mod.ClientModule),

  },
 ];
 
 @NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorage } from 'src/storage/local.storage';
import jwt_decode from "jwt-decode";
import { AnchorURLs } from 'src/routes/ng.routes';
import { SessionStorage } from 'src/storage/session.storage';
 @Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  _currentURL:any;
  remember:any;
  constructor(private router: Router,  private activatedRoute: ActivatedRoute) {
     this.activatedRoute.queryParams.subscribe(prams => {
      if(prams['dashboardNavigate'] !== undefined){
        sessionStorage.setItem("dashboardNavigate",prams['dashboardNavigate'])
      }
      else if(prams['dashboardNavigateScheduledCall'] !== undefined){
        sessionStorage.setItem("dashboardNavigateScheduledCall",prams['dashboardNavigateScheduledCall'])
      }
      if(prams["documentManagment"] !== undefined){
        sessionStorage.setItem("notiJobID", prams['param2']);
        sessionStorage.setItem("notiProfileID", prams['param1']);
      }
      // http://localhost:4400/#/client/dashboard?param1=63fc30f3103d65001329ee3a&param2=63fc30955f9c2000140a5e46&documentManagment=true
      if(prams['agreement'] !== undefined){
        sessionStorage.setItem("agreement",prams['agreement'])
      }
      if(prams['interviewsuggestedConsultant'] !== undefined){
        sessionStorage.setItem("interviewsuggestedConsultant",prams['interviewsuggestedConsultant'])
        sessionStorage.setItem('userId',prams['clientUserId'])
        sessionStorage.setItem('clientInterviewRequested',prams['clientInterviewRequested'])

      }
      if(prams['SeeAcknowledgment'] !== undefined){
        sessionStorage.setItem('seeAcknowledgmentNavigation','true')       
        sessionStorage.setItem('seeAcknowledgment', prams['ConsultantApplicationId'])
        sessionStorage.setItem('JobId',prams['clientJobId']) 
        sessionStorage.setItem('ConsultantId',prams['ConsultantApplicationId'])
      }
       if(prams['remember'] !== undefined){
        this.remember = prams['remember'];
        delete prams['remember']
      }
      

    })
    this.router.navigate([], {
      queryParams: {
        remember: null,
       },
      queryParamsHandling: 'merge'
    })
    this._currentURL = router.url
    if(this.remember == "true"){
      if(localStorage.getItem("token") == undefined){
        let token = this._currentURL.substring(this._currentURL.lastIndexOf('/') + 1)
        LocalStorage.Token = token.split('?')[0]
        LocalStorage.Auth_Data = jwt_decode(token)["authData"]
        this.router.navigateByUrl(AnchorURLs.client.dashboard)
      } 
      else {
        this.router.navigateByUrl(AnchorURLs.client.dashboard)
      }
    }
    else{
      if(sessionStorage.getItem("token") == undefined){
         let token = this._currentURL.substring(this._currentURL.lastIndexOf('/') + 1)
        SessionStorage.Token = token.split('?')[0]
        SessionStorage.Auth_Data = jwt_decode(token)["authData"]
        this.router.navigateByUrl(AnchorURLs.client.dashboard)
      } 
      else {
        this.router.navigateByUrl(AnchorURLs.client.dashboard)
      }
    }

   }
   ngOnInit(): void {
   
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone'; 
import { AMERICAN_TIMEZONE, TIMEZONE_SYMBOL } from 'src/enums/users.enum';
import { SessionStorage } from 'src/storage/session.storage';
@Pipe({
  name: 'timezone',
  pure:true
})
export class TimezonePipe implements PipeTransform {

  transform(value: any, format?:string): any {
    const data = SessionStorage.Auth_Data.Client_Time_Zone || 'Eastern Standard Time (EST)';
    const tz = AMERICAN_TIMEZONE[data];
    const name = TIMEZONE_SYMBOL[data];
    return moment(value).tz(tz).format(format || 'MM/DD/yyyy | hh:mm:ss A') + ' ' + name ;
  }

}

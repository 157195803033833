import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { InjectableRxStompConfig, InjectableRxStompRPCConfig, RxStompRPCService, RxStompService, rxStompServiceFactory } from '@stomp/ng2-stompjs';
import { Interceptor } from 'src/interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomPhonePipe } from './custome-Pipes/phonePipe.componnet';
 import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
// import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MatChipsModule } from '@angular/material/chips';
 import { CustomDateModule } from 'src/pipes/custom-date.pipe';
import { ChartsModule } from 'ng2-charts';
// import { ClientComponent } from './client/client.component';
// import { PdfViewerComponent } from './common/pdf-viewer/pdf-viewer.component';
import { ToastrModule } from 'ngx-toastr';
import { ToastrModelService } from './toastr-model.service';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule } from 'ngx-ui-loader';
import { HealthcheckComponent } from './healthcheck/healthcheck.component';
import { ApiService } from './api.service';
import { AuthComponent } from './auth/auth.component';
import { MessageBoxComponent } from './common/message-box/message-box.component';
// import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
// const config: SocketIoConfig = { url: 'https://devserver.iagility.com', options: {} };
@NgModule({
  declarations: [
    AppComponent,
    CustomPhonePipe,
    HealthcheckComponent,
    AuthComponent,
    MessageBoxComponent,
     // ClientComponent,
    // PdfViewerComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    // OwlDateTimeModule,
    // OwlNativeDateTimeModule,
    MatChipsModule,
    CustomDateModule,
    ChartsModule,
    ToastrModule.forRoot(),
    NgxUiLoaderModule,
    NgxUiLoaderHttpModule.forRoot({ showForeground: true }),
    // SocketIoModule.forRoot(config)

  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    ToastrModelService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    },
    ApiService
   ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-healthcheck',
  templateUrl: './healthcheck.component.html',
  styleUrls: ['./healthcheck.component.scss']
})
export class HealthcheckComponent implements OnInit {

  constructor(public ApiService:ApiService) { }
  public response:any;
  ngOnInit(): void {
    this.ApiService.live().subscribe(res => {
        console.log(res["status"])
        this.response = res["status"];
    }, err => {
     })
  }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { LocalStorage } from 'src/storage/local.storage';
import { AnchorURLs, ngRoutes } from 'src/routes/ng.routes';
import { API_ROUTE } from 'src/routes/api.routes';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { SessionStorage } from 'src/storage/session.storage';
import { ToastrModelService } from '../toastr-model.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ClientServiceService {
  timeSelectionArray = [
    "8:00 AM",
    "8:30 AM",
    "9:00 AM",
    "9:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "1:00 PM",
    "1:30 PM",
    "2:00 PM",
    "2:30 PM",
    "3:00 PM",
    "3:30 PM",
    "4:00 PM",
    "4:30 PM",
    "5:00 PM",
    "5:30 PM",
    "6:00 PM",
    "6:30 PM",
    "7:00 PM",
    "7:30 PM",
    "8:00 PM",
    "8:30 PM",
    "9:00 PM",
    "9:30 PM",
    "10:00 PM",
    "10:30 PM",
    "11:00 PM",
    "11:30 PM",
    "12:00 AM",
    "12:30 AM",
    "1:00 AM",
    "1:30 AM",
    "2:00 AM",
    "2:30 AM",
    "3:00 AM",
    "3:30 AM",
    "4:00 AM",
    "4:30 AM",
    "5:00 AM",
    "5:30 AM",
    "6:00 AM",
    "6:30 AM",
    "7:00 AM",
    "7:30 AM",
  ];
   consultantAvalability: BehaviorSubject<any>;
  InterviewRequestMessage: Subject<any>;
  countZeroOnNotificationReload : Subject<any>
  newNotifications: Subject<any>;
  jobSaved: Subject<any>;
  removeJobInProfileMatches: Subject<any>;
  updateViewed: Subject<any>;
  updateOtherStatusOnClick: Subject<any>;
  openPersonalityTest: Subject<boolean>;
  forgetPassword: Subject<any>;
  editProject: Subject<any>;
  editProjectDetail: Subject<boolean>;
  changeStatus: Subject<boolean>;
  profileMatches_ID: BehaviorSubject<any>;
  updateApp_ID: BehaviorSubject<any>;
  getDocument:BehaviorSubject<any>;
  reload:Subject<any>;
  profileMAtches_Filter: Subject<boolean>;
  profileselectedFilter: Subject<boolean>;
  applicationID: Subject<string> = new Subject();
  featuredSelected: Subject<boolean>;
  featured_ID: Subject<boolean>;
  disabledMenu: Subject<boolean>;
  clientProjectUpdateChnages: Subject<any>
  selectZeroPositionJob: Subject<any>;
  selectZeroPositionJobProfileMatches: Subject<any>;
  selectedJOBDetails: Subject<any>;
  availabilityOfClient: BehaviorSubject<any>;
  billrateOfClient:BehaviorSubject<any>;
  profileMatchesListFlag: Subject<boolean>;
   constructor(private http: HttpClient, private toaster: ToastrModelService,) {
    this.countZeroOnNotificationReload = new Subject()
    this.newNotifications= new Subject()
    this.removeJobInProfileMatches= new Subject()
    this.InterviewRequestMessage = new Subject();
    this.consultantAvalability = new BehaviorSubject({});
    this.updateViewed = new Subject();
    this.updateOtherStatusOnClick =  new Subject();
    this.jobSaved = new Subject();
    this.openPersonalityTest = new Subject();
    this.editProject = new Subject();
    this.editProjectDetail = new Subject();
    this.changeStatus = new Subject();
    this.reload = new Subject()
    this.profileMatches_ID = new BehaviorSubject({});
    this.updateApp_ID = new BehaviorSubject({});
    this.getDocument = new BehaviorSubject({});
    this.profileMAtches_Filter = new Subject();
    this.profileselectedFilter = new Subject();
    this.featured_ID = new Subject();
    this.featuredSelected = new Subject();
    this.disabledMenu = new Subject();
    this.forgetPassword = new Subject();
    this.clientProjectUpdateChnages = new Subject()
    this.selectZeroPositionJob = new Subject();
    this.selectZeroPositionJobProfileMatches = new Subject();
    this.availabilityOfClient = new BehaviorSubject({});
    this.billrateOfClient =new BehaviorSubject({});
    this.selectedJOBDetails = new Subject();
    this.profileMatchesListFlag = new Subject();

   }
  /**
   * 
   * @param image / Company logo
   * @returns success  / error 
   */
  changeClientLogo(image) {
    const headers = new HttpHeaders({
      'Accept': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    console.log(image)

    let formData = new FormData();
    formData.append("profile_pic", image);

    return this.http.post(API_ROUTE.Client.change_Logo, formData, { headers: headers }).pipe(
      map<any, any>((res) => {
        console.log(res.result);
        return res;
      })
    );
  }
  /**
   * Check auth information set in LocalStorage / SessionStorage 
   * If data/token not found in any Storage then user logged out
   * @returns Token of loggedin User
   */
  public count = 0;
  checkStorage() {
    if (LocalStorage.Token !== undefined && LocalStorage.Token != '' && LocalStorage.Token !== null) {
      return localStorage.getItem("token")
    }
    else if (SessionStorage.Token !== undefined && SessionStorage.Token != '' && SessionStorage.Token !== null) {
      return sessionStorage.getItem("token")
    }
    else {
      if (this.count === 0) {
        this.count++;
        this.toaster.error("Session expired, please login.")
        this.logout()
      }
    }
  }
  /**
   * Loggout User
   * Navigate back to login page
   */
  logout() {
    LocalStorage.removeAll();
    window.location.href = environment.account_url + '#' + AnchorURLs.account.logout
  }
  /**
   * 
   * @param obj  (Client Id )
   * @returns success (Client Information/Detaisl) / Error
   */
  getClientDetails(obj) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + localStorage.getItem("token"),
    })
    return this.http.post(API_ROUTE.Client.get_Info, obj, { headers: headers }).pipe(
      map<any, any>((res) => {
        return res;
      })
    );
  }
  /**
   * get Client Statuses Counts
   * @returns return count / error
   */
  getClientStatusesCount() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.post(API_ROUTE.Client.statuses_count, null, { headers: headers }).pipe(
      map<any, any>((res) => {
        return res;
      })
    );
  }
  /**
   * Get Job/Project Count
   * @returns Count / error
   */
  getJobCount() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.get(API_ROUTE.Client.job_Count, { headers: headers }).pipe(
      map<any, any>((res) => {
        return res;
      })
    );
  }
  /**
 * Get Open Job/Project Count
 * @returns Count / error
 */
  getOpenJobCount(obj) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.post(API_ROUTE.Client.open_job_Count, obj, { headers: headers }).pipe(
      map<any, any>((res) => {
        return res;
      })
    );
  }
  /**
   * Get Job/Project Submissions Count
   * @returns Count / error
   */
  getSubmissionCount() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.get(API_ROUTE.Client.submission_Count, { headers: headers }).pipe(
      map<any, any>((res) => {
        return res;
      })
    );
  }
  /**
   * Get List of Jobs/Projects (get id from Storage)
   * @returns List of Jobs/Projects 
   */
  getJobsList() {
    let obj;
    if (LocalStorage.Token !== undefined && LocalStorage.Token != '' && LocalStorage.Token !== null) {
      obj = {
        id: LocalStorage.Auth_Data["id"]
      }
    }
    else if (SessionStorage.Token !== undefined && SessionStorage.Token != '' && SessionStorage.Token !== null) {
      obj = {
        id: SessionStorage.Auth_Data["id"]
      }
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.post(API_ROUTE.Client.Get_Projects_List, obj, { headers: headers }).pipe(
      map<any, any>((res) => {
        console.log(res);
        return res;
      })
    );
  }
  /**
   * Get Project/Submission Details
   * @param obj (Job id) = _id
   * @returns Details of Submission/Project
   */
  getSubmissionDetail(obj) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.post(API_ROUTE.Consultant.Get_Submission_Detail, obj, { headers: headers }).pipe(
      map<any, any>((res) => {
        console.log(res);
        return res;
      })
    );
  }
  /**
   * Get Project/Job Details
   * @param obj (Job id) = _id
   * @returns Details of Job/Project
   */
  getJobsDetail(obj) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.post(API_ROUTE.Client.Get_Project_Detail, obj, { headers: headers }).pipe(
      map<any, any>((res) => {
        console.log(res);
        return res;
      })
    );
  }
  /**
   * Get Featured Consultants Records
   * @returns List of Consultant (Featured)
   */
  getFeaturedConsultantList() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.post(API_ROUTE.Client.Get_FeaturedConsultant_List, null, { headers: headers }).pipe(
      map<any, any>((res) => {
        console.log(res);
        return res;
      })
    );
  }
  /**
   * Ask iAgility page (help information)
   * @param obj  (user help info)
   * @returns Success / Error (Email sent)
   */
  saveaskIAgility(obj) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.post(API_ROUTE.Client.askIAgility, obj, { headers: headers }).pipe(
      map<any, any>((res) => {
        console.log(res);
        return res;
      })
    );
  }
  makeInquey(obj) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.post(API_ROUTE.Client.makeInquey, obj, { headers: headers }).pipe(
      map<any, any>((res) => {
        console.log(res);
        return res;
      })
    );
  }
  /**
   * 
   * @param obj 
   * @returns Infomation of admin
   */
  getaskIAgility(obj) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.post(API_ROUTE.Client.getaskIAgility, obj, { headers: headers }).pipe(
      map<any, any>((res) => {
        console.log(res);
        return res;
      })
    );
  }
  /**
   * Upload Agreement File with name
   * @param file 
   * @param fileName 
   * @returns Success / Error
   */
  uploadAgreement(file, fileName) {
    const headers = new HttpHeaders({
      // 'Content-Type': 'multipart/form-data',
      // 'Accept': '*/*',
      'Accept': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    let formData = new FormData();
    formData.append("Agreement_Title", fileName)
    formData.append("client_agreement", file);

    return this.http.post(API_ROUTE.Client.upload_Agreement, formData, { headers: headers }).pipe(
      map<any, any>((res) => {
        console.log(res.result);
        return res;
      })
    );
  }
  /**
   * Close Project/Job
   * @param id 
   * @param status 
   * @returns Updated Details of Job
   */
  closeProject(data) {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.post(API_ROUTE.Client.update_Project, data, { headers: headers }).pipe(
      map<any, any>((res) => {
        console.log(res);
        return res;
      })
    );
  }
  /**
   * Update/Edit Details of Job/Project
   * @param obj  (Project Information object)
   * @returns Updated Details of Job/Project
   */
  updateProject(obj) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.post(API_ROUTE.Client.update_Project, obj, { headers: headers }).pipe(
      map<any, any>((res) => {
        console.log(res);
        return res;
      })
    );
  }
  /**
   * Get Profile Matches List
   * @param id (Consultant id) = _id
   * @returns List of Consultants 
   */
  getProfileMatchesList(id) {
    let obj = {
      JOBID: id
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.post(API_ROUTE.Client.Get_Profile_Matches_List, obj, { headers: headers }).pipe(
      map<any, any>((res) => {
        console.log(res);
        return res;
      })
    );
  }
  /**
   * Upload Picture Registration 
   * @param file 
   * @param status 
   * @param min 
   * @param max 
   * @returns File and Details
   */
  uploadPicture_Registration(file, status, min, max) {
    const headers = new HttpHeaders({
      // 'Content-Type': 'multipart/form-data',
      // 'Accept': '*/*',
      'Accept': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    let formData = new FormData();
    formData.append("preffered_bill_rate", status)
    formData.append("min", min)
    formData.append("max", max)
    formData.append("jobFile", file);

    return this.http.post(API_ROUTE.Client.register_byFile, formData, { headers: headers }).pipe(
      map<any, any>((res) => {
        console.log(res.result);
        return res;
      })
    );
  }
  /**
   * Save Client Information
   * @param obj 
   * @returns Client Information
   */
  saveClient(obj) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.post(API_ROUTE.Client.saveClient, obj, { headers: headers }).pipe(
      map<any, any>(res => {
        return res
      })
    )
  }
  /**
   * Get Details of Consultant
   * @param obj 
   * @returns Consultant Details
   */
  getConsultantDetail(obj) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.post(API_ROUTE.Client.getGCID, obj, { headers: headers }).pipe(
      map<any, any>((res) => {
        console.log(res);
        return res;
      })
    );
  }
  /**
   * Consultant Application Status change
   * @param obj 
   * @returns Update Information1
   */
  updateStatus(obj) {
    console.log("heloo", obj)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.post(API_ROUTE.Client.updateApplicationStatus, obj, { headers: headers }).pipe(
      map<any, any>((res) => {
        console.log(res);
        return res;
      })
    );
  }
  /**
   * Consultant Offer Extend
   * @param obj 
   * @returns Updated Offer Extended (On-boarding process infotmation
   */
  extendOffer(obj) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.post(API_ROUTE.Client.generateExtentendAnoffer, obj, { headers: headers }).pipe(
      map<any, any>((res) => {
        console.log(res);
        return res;
      })
    );
  }
    /**
   * Client Offer Extend non obp
   * @param obj 
   * @returns Updated Offer Extended (On-boarding process infotmation
   */
    extendOfferNonOBP(obj) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ` + this.checkStorage(),
      })
      return this.http.post(API_ROUTE.Client.generateExtentendAnofferNonOBP, obj, { headers: headers }).pipe(
        map<any, any>((res) => {
          console.log(res);
          return res;
        })
      );
    }
  /**
   * Create Interview / Schedule Meeting
   * @param obj 
   * @returns Information of Meeting
   */
  generateInterview(formData) {
        const headers = new HttpHeaders({
      // 'Content-Type': 'multipart/form-data',
      // 'Accept': '*/*',
      'Accept': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.post(API_ROUTE.Client.generateInterview, formData, { headers: headers }).pipe(
      map<any, any>((res) => {
        return res;
      })
    );
  }
  /**
   * Get consultant Buisness Skills
   * @param obj ( consultant id)
   * @returns get list of Skills
   */
  getBuisnessSkillName(obj) {
    return this.http.post(API_ROUTE.Consultant.get_Buisness_Skill_Info, obj).pipe(
      map<any, any>((res) => {
        console.log(res.result);
        return res;
      })
    );
  }
  /**
   * Get Occupied Dates for Calender (Meeting Scheduled)
   * @param obj 
   * @returns Occupied Dates
   */
  getOccupiedDates(obj) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.post(API_ROUTE.Client.getOccopiedDateOfMonth, obj, { headers: headers }).pipe(
      map<any, any>(res => {
        return res
      })
    )
  }
  /**
   * Get OCcupied time slotes according to Date
   * @param obj  Date 
   * @returns time slots (EST by default)
   */
  getOccupiedDateSlots(obj) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.post(API_ROUTE.Client.getOccupiedDateSlots, obj, { headers: headers }).pipe(
      map<any, any>(res => {
        return res
      })
    )
  }
  /**
  * Get OCcupied time slotes according to Date and Time Zones
   * @param obj  Date 
   * @returns time slots as per time zone
   */
  getSlotsByZones(obj) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.post(API_ROUTE.Client.getSlotsByZones, obj, { headers: headers }).pipe(
      map<any, any>(res => {
        return res
      })
    )
  }
  /**
   * Schedule Meeting
   * @param obj  (Date , Time , TimeZone, Id)
   * @returns Saved information of meeting
   */
  saveMeeting(obj) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.post(API_ROUTE.Client.saveMeeting, obj, { headers: headers }).pipe(
      map<any, any>(res => {
        return res
      })
    )
  }
  /**
 * Take to my dashobard send Email first time from landing page of client
 * @param obj 
 * @returns email
 */
  projectCallDashboard(obj) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.post(API_ROUTE.Client.projectCallDashboard, obj, { headers: headers }).pipe(
      map<any, any>(res => {
        return res
      })
    )
  }
  SaveJob(obj) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.post(API_ROUTE.Client.saveJob, obj, { headers: headers }).pipe(
      map<any, any>(res => {
        return res
      })
    )
  }
  /**
   * Chnage Password
   * @param old 
   * @param newPass 
   * @returns Success / Error
   */
  passwordChange(old, newPass) {
    let body = {
      "OldPassword": old,
      "NewPassword": newPass
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.post(API_ROUTE.Account.passwordChnage, body, { headers: headers }).pipe(
      map<any, any>(res => {
        return res
      })
    )
  }
  /**
   *  Take to my Dashboard check enable
   * @param obj 
   * @returns message
   */
  takeToMyDashboardCheck(obj) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.checkStorage(),
    })
    return this.http.post(API_ROUTE.Account.take_to_my_dashboard_check, obj, { headers: headers }).pipe(
      map<any, any>((res) => {
        return res;
      })
    )
  }
  /**
   * Save Agreemnt Details
   * @param data
   */

  postAgreement(data) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.post(API_ROUTE.Client.agreementSave, data, { headers: headers }).pipe(
      map<any, any>((res) => {
        return res;
      })
    );
  }
  /**
   * save/update timezone
   * @param timezone 
   * @returns 
   */
  timezone(timezone) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    let obj = {
      "client": {
        "client_timeZone": timezone
    }}
    return this.http.post(API_ROUTE.Client.clientTimeZone, obj, { headers: headers }).pipe(
      map<any, any>((res) => {
        SessionStorage.updateTimeZone = timezone;
        return res;
      })
    );
  }
  gettimezone(obj) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.post(API_ROUTE.Client.getclientTimeZone, obj, { headers: headers }).pipe(
      map<any, any>((res) => {
        return res;
      })
    );
  }
  findOneApplication(obj) {
    
    console.log("obj", obj)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.post(API_ROUTE.Client.findOneApplication, obj, { headers: headers }).pipe(
      map<any, any>((res) => {
        console.log(res);
        return res;
      })
    );
  }
  SuggestInterviewAccept(obj) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.post(API_ROUTE.Client.SuggestInterviewClient, obj, { headers: headers }).pipe(
      map<any, any>((res) => {
        console.log(res);
        return res;
      })
    );
  }
  interviewAccept(obj) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.post(API_ROUTE.Client.interviewAccept, obj, { headers: headers }).pipe(
      map<any, any>((res) => {
        console.log(res);
        return res;
      })
    );
  }
  downloadFileDM(obj) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.post(API_ROUTE.Client.downloadFileDM, obj, { headers: headers }).pipe(
      map<any, any>((res) => {
        return res;
      })
    );
  }
  /**
   * Save  Hiring Status
   * @param data
   */

  saveHiringProcess(data) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.post(API_ROUTE.Client.saveHiring, data, { headers: headers }).pipe(
      map<any, any>((res) => {
        return res;
      })
    );
  }
   /**
   * Save Charity  Details
   * @param data
   */

   saveCharityProcess(data) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.post(API_ROUTE.Client.saveCharityProcess, data, { headers: headers }).pipe(
      map<any, any>((res) => {
        return res;
      })
    );
  }
   /**
   * Decline  Hiring Process
   * @param data
   */

   declinedApplicationHiringProcess(data) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + this.checkStorage(),
    })
    return this.http.post(API_ROUTE.Client.declinedApplicationHiringProcess, data, { headers: headers }).pipe(
      map<any, any>((res) => {
        return res;
      })
    );
  }
}
